import * as React from 'react';

export const StarSvg = ({
	stroke = '#e5006e',
	fill = '#e5006e',
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			role='img'
			aria-hidden='true'
			{...props}
		>
			<path
				fill={fill}
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M6.125 1.275l1.545 3.13 3.455.505-2.5 2.435.59 3.44-3.09-1.625-3.09 1.625.59-3.44-2.5-2.435 3.455-.505 1.545-3.13z'
			></path>
		</svg>
	);
};

export const SearchIconMobileSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 17.69 17.69' {...props}>
			<g transform='translate(-2.3 -2.3)'>
				<circle
					id='Ellipse_4'
					cx={7.097}
					cy={7.097}
					r={7.097}
					className='cls-1'
					strokeLinecap='round'
					fill='none'
					strokeWidth={1}
					stroke='#535353'
					transform='translate(3 3)'
				/>
				<path
					id='Line_12'
					d='M3.93 3.93L0 0'
					className='cls-1'
					strokeLinecap='round'
					fill='none'
					strokeWidth={1}
					stroke='#535353'
					transform='translate(15.07 15.07)'
				/>
			</g>
		</svg>
	);
};

export const HelpIconMobileSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			fill='none'
			viewBox='0 0 20 20'
			{...props}
		>
			<path
				fill='#444'
				fillRule='evenodd'
				d='M10 2.292a7.708 7.708 0 100 15.416 7.708 7.708 0 000-15.416zM1.042 10a8.958 8.958 0 1117.916 0 8.958 8.958 0 01-17.916 0z'
				clipRule='evenodd'
			></path>
			<path
				stroke='#444'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M7.5 7.504a2.5 2.5 0 014.858.833c0 1.667-2.5 2.5-2.5 2.5'
			></path>
			<mask id='path-3-inside-1' fill='#fff'>
				<ellipse cx='10' cy='14.167' rx='0.833' ry='0.833'></ellipse>
			</mask>
			<ellipse
				cx='10'
				cy='14.167'
				fill='#444'
				rx='0.833'
				ry='0.833'
			></ellipse>
			<path
				fill='#444'
				d='M9.833 14.167c0-.092.075-.167.167-.167v2c1.012 0 1.833-.82 1.833-1.833h-2zM10 14c.092 0 .166.075.166.167h-2C8.166 15.179 8.987 16 10 16v-2zm.166.167a.167.167 0 01-.166.166v-2c-1.013 0-1.834.821-1.834 1.834h2zm-.166.166a.167.167 0 01-.167-.166h2c0-1.013-.82-1.834-1.833-1.834v2z'
				mask='url(#path-3-inside-1)'
			></path>
		</svg>
	);
};

export const OnlineGiftBoxSvg = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 183 158' fill='none' {...props}>
			<path
				d='M138.271 63.3473H67.0609C66.1008 63.3473 65.3224 64.1254 65.3224 65.0853V155.872C65.3224 156.831 66.1008 157.61 67.0609 157.61H138.271C139.231 157.61 140.01 156.831 140.01 155.872V65.0853C140.01 64.1254 139.231 63.3473 138.271 63.3473Z'
				fill='#7300E5'
			/>
			<path
				d='M121.129 63.3473H138.124C138.624 63.3473 139.104 63.5459 139.457 63.8995C139.811 64.2531 140.01 64.7326 140.01 65.2326V155.724C140.01 156.224 139.811 156.704 139.457 157.057C139.104 157.411 138.624 157.61 138.124 157.61H119.243V65.2326C119.243 64.985 119.291 64.7399 119.386 64.5111C119.481 64.2824 119.62 64.0746 119.795 63.8995C119.97 63.7244 120.178 63.5856 120.407 63.4908C120.636 63.3961 120.881 63.3473 121.129 63.3473Z'
				fill='#330066'
			/>
			<path
				opacity={0.26}
				d='M140.01 65.653H65.3224V69.6664H140.01V65.653Z'
				fill='black'
			/>
			<path
				d='M62.3438 53.5826H123.613V67.0573H62.3438C61.8769 67.0573 61.4292 66.8719 61.0991 66.5419C60.769 66.2119 60.5836 65.7643 60.5836 65.2976V55.3162C60.5904 54.8541 60.7788 54.4131 61.1082 54.0887C61.4376 53.7643 61.8814 53.5825 62.3438 53.5826Z'
				fill='#7300E5'
			/>
			<path
				opacity={0.26}
				d='M98.3587 100.395L100.5 69.6708H92.0983L98.3587 100.395Z'
				fill='black'
			/>
			<path
				d='M123.613 53.5826H140.799C141.261 53.5825 141.705 53.7643 142.034 54.0887C142.364 54.4131 142.552 54.8541 142.559 55.3162V65.2846C142.559 65.7513 142.373 66.1989 142.043 66.5289C141.713 66.8589 141.265 67.0443 140.799 67.0443H123.613V53.5826Z'
				fill='#330066'
			/>
			<path
				d='M98.3587 53.5739H85.8422V157.61H98.3587V53.5739Z'
				fill='#ADC6FF'
			/>
			<path
				d='M135.887 53.5739H129.626V157.61H135.887V53.5739Z'
				fill='#9BB4EF'
			/>
			<path
				d='M101.125 53.5826C97.369 51.8105 93.73 49.8012 90.2297 47.5668C86.0315 44.89 82.0429 41.8981 78.2984 38.6169C80.0303 34.8726 82.7449 31.6673 86.1534 29.342C89.562 27.0167 93.5372 25.6582 97.6564 25.4109C99.8241 28.4188 102.079 31.7387 104.29 35.3794C106.852 39.5921 109.02 43.6402 110.858 47.4282C114.055 42.0011 118.356 37.3056 123.483 33.6457C125.739 34.7957 127.648 36.5265 129.012 38.6597C130.376 40.7929 131.146 43.2512 131.243 45.7812C126.618 49.1485 121.499 51.7801 116.069 53.5826H101.125Z'
				fill='#9BB4EF'
			/>
			<path
				opacity={0.26}
				d='M110.858 47.4238L116.061 53.5739H112.401L110.858 47.4238Z'
				fill='black'
			/>
			<path
				d='M110.858 47.4238C111.802 49.4233 112.628 51.4769 113.329 53.5739H101.125C99.603 52.8544 97.7561 51.9356 95.6924 50.8001C93.7198 49.7166 92.224 48.8194 91.6257 48.4467C88.6201 46.5969 85.7168 44.5859 82.9288 42.4223C81.6671 41.4471 80.0847 40.1729 78.3115 38.6169C79.5021 36.0729 81.1427 33.7648 83.1542 31.8037C83.9062 31.0695 84.7088 30.3888 85.5561 29.7667C87.2209 28.5519 89.0424 27.5678 90.9711 26.8412C91.9898 26.4379 93.0414 26.1232 94.1143 25.9007C95.6881 25.5843 97.6564 25.4066 97.6564 25.4066C97.6564 25.4066 99.7981 28.3798 100.479 29.342C102.326 31.9424 103.57 34.0835 105.248 36.9916C106.839 39.7135 108.773 43.2111 110.858 47.4238Z'
				fill='#ADC6FF'
			/>
			<path
				d='M78.4458 120.349H7.23548C6.27532 120.349 5.49695 121.127 5.49695 122.087V155.872C5.49695 156.831 6.27532 157.61 7.23548 157.61H78.4458C79.406 157.61 80.1843 156.831 80.1843 155.872V122.087C80.1843 121.127 79.406 120.349 78.4458 120.349Z'
				fill='#B266FF'
			/>
			<path
				d='M61.3032 120.25H78.2983C78.7985 120.25 79.2782 120.448 79.6319 120.802C79.9855 121.155 80.1842 121.635 80.1842 122.135V155.724C80.1842 156.224 79.9855 156.704 79.6319 157.057C79.2782 157.411 78.7985 157.61 78.2983 157.61H59.4173V122.135C59.4173 121.635 59.616 121.155 59.9697 120.802C60.3234 120.448 60.8031 120.25 61.3032 120.25Z'
				fill='#9933FF'
			/>
			<path
				opacity={0.26}
				d='M80.1843 120.159H5.49695V124.172H80.1843V120.159Z'
				fill='black'
			/>
			<path
				d='M2.52278 108.079H63.7832V121.554H2.52278C2.05594 121.554 1.60823 121.369 1.27813 121.039C0.948024 120.709 0.762573 120.261 0.762573 119.795V109.826C0.766006 109.362 0.952973 108.917 1.2827 108.59C1.61244 108.263 2.05819 108.079 2.52278 108.079Z'
				fill='#B266FF'
			/>
			<path
				opacity={0.26}
				d='M38.5334 154.901L40.6752 124.172H32.273L38.5334 154.901Z'
				fill='black'
			/>
			<path
				d='M63.7832 108.079H80.9691C81.4359 108.079 81.8836 108.265 82.2137 108.595C82.5438 108.925 82.7293 109.372 82.7293 109.839V119.808C82.7293 120.274 82.5438 120.722 82.2137 121.052C81.8836 121.382 81.4359 121.567 80.9691 121.567H63.7832V108.079Z'
				fill='#9933FF'
			/>
			<path
				d='M38.5334 108.079H26.0168V157.614H38.5334V108.079Z'
				fill='#ECD9FF'
			/>
			<path
				d='M76.0613 108.079H69.8009V157.614H76.0613V108.079Z'
				fill='#CFB1EE'
			/>
			<path
				d='M41.2993 108.08C33.0352 104.178 25.3566 99.1428 18.486 93.1182C20.2182 89.3763 22.9309 86.1727 26.3367 83.847C29.7424 81.5213 33.7142 80.1601 37.8309 79.9079C39.9987 82.9158 42.2531 86.2357 44.4685 89.8763C47.0265 94.0891 49.1942 98.1371 51.0325 101.921C54.2348 96.5039 58.5352 91.8166 63.6574 88.16C65.9091 89.3089 67.8146 91.0359 69.1784 93.1639C70.5421 95.2919 71.3151 97.7443 71.4179 100.269C66.7939 103.639 61.675 106.271 56.2437 108.071L41.2993 108.08Z'
				fill='#CFB1EE'
			/>
			<path
				opacity={0.26}
				d='M51.0327 101.925L56.2352 108.079H52.5761L51.0327 101.925Z'
				fill='black'
			/>
			<path
				d='M51.0325 101.925C51.9767 103.926 52.8018 105.981 53.5037 108.08H41.2993C39.7776 107.356 37.9306 106.437 35.8669 105.301C33.8943 104.222 32.3986 103.321 31.8046 102.948C28.797 101.099 25.8923 99.088 23.1033 96.9235C21.8417 95.944 20.2635 94.6698 18.486 93.1139C19.6767 90.5698 21.3173 88.2617 23.3287 86.3007C24.0831 85.569 24.8855 84.8885 25.7306 84.2637C27.396 83.0499 29.2174 82.0658 31.1456 81.3381C32.1646 80.9366 33.2162 80.6234 34.2888 80.402C35.8626 80.0813 37.8309 79.9036 37.8309 79.9036C37.8309 79.9036 39.977 82.8811 40.6577 83.8389C42.5046 86.4394 43.7445 88.5804 45.4267 91.4929C47.0135 94.2104 48.9471 97.7167 51.0325 101.925Z'
				fill='#ECD9FF'
			/>
			<path
				d='M19.2057 68.4919C19.393 68.4894 19.578 68.4498 19.7499 68.3754C19.9218 68.301 20.0772 68.1933 20.2072 68.0585L21.5426 66.7236C21.7892 66.4543 21.9224 66.1003 21.9143 65.7353C21.9063 65.3703 21.7577 65.0225 21.4994 64.7643C21.2412 64.5062 20.8933 64.3576 20.5282 64.3496C20.1631 64.3416 19.8089 64.4747 19.5396 64.7212L18.1999 66.0561C18.0018 66.2546 17.867 66.5073 17.8126 66.7824C17.7582 67.0575 17.7866 67.3425 17.8943 67.6014C18.002 67.8603 18.1841 68.0815 18.4175 68.2369C18.6509 68.3923 18.9253 68.475 19.2057 68.4745V68.4919Z'
				fill='#ECD9FF'
			/>
			<path
				d='M12.5203 75.1707C12.7061 75.1713 12.8902 75.1352 13.062 75.0645C13.2339 74.9939 13.3901 74.89 13.5218 74.759L14.8614 73.4197C14.9929 73.288 15.0972 73.1316 15.1683 72.9596C15.2393 72.7875 15.2758 72.6032 15.2756 72.417C15.2754 72.2309 15.2385 72.0466 15.1671 71.8747C15.0957 71.7028 14.991 71.5467 14.8592 71.4152C14.7274 71.2837 14.571 71.1795 14.3989 71.1085C14.2268 71.0374 14.0424 71.001 13.8562 71.0012C13.67 71.0014 13.4857 71.0382 13.3137 71.1096C13.1418 71.1811 12.9856 71.2856 12.8541 71.4174L11.5188 72.7523C11.3209 72.9505 11.1862 73.2028 11.1316 73.4775C11.0771 73.7522 11.1052 74.0368 11.2123 74.2956C11.3194 74.5543 11.5008 74.7755 11.7336 74.9312C11.9664 75.087 12.2401 75.1703 12.5203 75.1707Z'
				fill='#ECD9FF'
			/>
			<path
				d='M20.2072 71.4174C19.9416 71.1513 19.5811 71.0016 19.205 71.0012C18.829 71.0008 18.4682 71.1497 18.202 71.4152C17.9358 71.6808 17.7861 72.0411 17.7856 72.417C17.7852 72.793 17.9342 73.1536 18.1998 73.4197L19.5395 74.759C19.6712 74.89 19.8274 74.9939 19.9992 75.0645C20.1711 75.1352 20.3552 75.1713 20.541 75.1707C20.8211 75.1703 21.0948 75.087 21.3276 74.9312C21.5604 74.7755 21.7418 74.5543 21.849 74.2956C21.9561 74.0368 21.9842 73.7522 21.9296 73.4775C21.8751 73.2028 21.7404 72.9505 21.5425 72.7523L20.2072 71.4174Z'
				fill='#ECD9FF'
			/>
			<path
				d='M12.8544 68.0758C12.9859 68.2076 13.1421 68.3121 13.314 68.3835C13.486 68.455 13.6703 68.4918 13.8565 68.492C14.0427 68.4922 14.2271 68.4558 14.3992 68.3847C14.5713 68.3137 14.7277 68.2094 14.8595 68.078C14.9914 67.9465 15.096 67.7903 15.1674 67.6185C15.2388 67.4466 15.2757 67.2623 15.2759 67.0762C15.2761 66.89 15.2396 66.7057 15.1686 66.5336C15.0975 66.3616 14.9932 66.2052 14.8617 66.0735L13.5221 64.7386C13.2527 64.492 12.8986 64.3589 12.5335 64.3669C12.1684 64.375 11.8204 64.5235 11.5622 64.7817C11.304 65.0398 11.1554 65.3876 11.1473 65.7526C11.1393 66.1176 11.2725 66.4716 11.5191 66.7409L12.8544 68.0758Z'
				fill='#ECD9FF'
			/>
			<path
				d='M164.418 96.0784C164.606 96.0764 164.792 96.0371 164.965 95.9627C165.138 95.8883 165.294 95.7803 165.424 95.645L166.76 94.3101C167.025 94.044 167.174 93.6833 167.174 93.3074C167.173 92.9314 167.024 92.5711 166.757 92.3056C166.491 92.04 166.13 91.8911 165.754 91.8915C165.378 91.8919 165.018 92.0416 164.752 92.3077L163.417 93.6426C163.219 93.8408 163.084 94.0932 163.03 94.3678C162.975 94.6425 163.003 94.9272 163.11 95.1859C163.218 95.4446 163.399 95.6658 163.632 95.8216C163.865 95.9773 164.138 96.0607 164.418 96.061V96.0784Z'
				fill='#ECD9FF'
			/>
			<path
				d='M157.738 102.757C157.925 102.755 158.11 102.716 158.282 102.641C158.454 102.567 158.609 102.459 158.739 102.324L160.079 100.989C160.21 100.857 160.314 100.701 160.386 100.529C160.457 100.357 160.493 100.172 160.493 99.9862C160.493 99.8001 160.456 99.6158 160.384 99.4439C160.313 99.272 160.208 99.1159 160.077 98.9844C159.945 98.8529 159.788 98.7487 159.616 98.6776C159.444 98.6066 159.26 98.5701 159.073 98.5703C158.887 98.5705 158.703 98.6074 158.531 98.6788C158.359 98.7502 158.203 98.8548 158.071 98.9866L156.736 100.321C156.538 100.52 156.403 100.772 156.349 101.047C156.294 101.321 156.322 101.606 156.43 101.865C156.537 102.123 156.718 102.345 156.951 102.5C157.184 102.656 157.457 102.74 157.738 102.74V102.757Z'
				fill='#ECD9FF'
			/>
			<path
				d='M165.424 99.0039C165.293 98.8721 165.137 98.7675 164.965 98.6961C164.793 98.6247 164.608 98.5878 164.422 98.5876C164.236 98.5874 164.052 98.6239 163.88 98.695C163.707 98.766 163.551 98.8702 163.419 99.0017C163.287 99.1332 163.183 99.2893 163.111 99.4612C163.04 99.6331 163.003 99.8174 163.003 100.004C163.003 100.19 163.039 100.374 163.11 100.546C163.181 100.718 163.285 100.874 163.417 101.006L164.752 102.345C165.019 102.612 165.38 102.761 165.756 102.761C166.132 102.761 166.493 102.612 166.76 102.345C167.026 102.079 167.175 101.718 167.175 101.342C167.175 100.966 167.026 100.605 166.76 100.339L165.424 99.0039Z'
				fill='#ECD9FF'
			/>
			<path
				d='M158.071 95.6623C158.203 95.7941 158.359 95.8986 158.531 95.9701C158.703 96.0415 158.887 96.0783 159.073 96.0785C159.26 96.0787 159.444 96.0423 159.616 95.9712C159.788 95.9002 159.945 95.796 160.076 95.6645C160.208 95.533 160.313 95.3769 160.384 95.205C160.456 95.0331 160.493 94.8488 160.493 94.6627C160.493 94.4765 160.457 94.2922 160.386 94.1201C160.314 93.9481 160.21 93.7917 160.079 93.66L158.73 92.3251C158.458 92.0994 158.112 91.9836 157.759 92.0002C157.405 92.0168 157.071 92.1646 156.822 92.4148C156.572 92.6649 156.425 92.9993 156.409 93.3524C156.393 93.7054 156.51 94.0516 156.736 94.3231L158.071 95.6623Z'
				fill='#ECD9FF'
			/>
			<path
				d='M39.2228 52.9498C39.2228 56.417 41.7591 59.2472 44.8589 59.2472C46.2636 59.2472 47.4039 60.5171 47.4039 62.0817C47.3779 62.3017 47.3988 62.5248 47.4652 62.7362C47.5317 62.9476 47.6422 63.1425 47.7894 63.3081C47.9366 63.4737 48.1173 63.6062 48.3195 63.697C48.5217 63.7878 48.7408 63.8347 48.9625 63.8347C49.1841 63.8347 49.4032 63.7878 49.6054 63.697C49.8076 63.6062 49.9883 63.4737 50.1355 63.3081C50.2828 63.1425 50.3932 62.9476 50.4597 62.7362C50.5262 62.5248 50.5471 62.3017 50.5211 62.0817C50.5211 58.6144 47.9805 55.7799 44.8589 55.7799C43.4586 55.7799 42.314 54.51 42.314 52.9498C42.3371 52.7317 42.314 52.5111 42.2463 52.3025C42.1786 52.0938 42.0677 51.9018 41.921 51.7387C41.7742 51.5757 41.5948 51.4453 41.3944 51.3561C41.1939 51.2668 40.977 51.2207 40.7576 51.2207C40.5382 51.2207 40.3212 51.2668 40.1208 51.3561C39.9203 51.4453 39.7409 51.5757 39.5942 51.7387C39.4474 51.9018 39.3365 52.0938 39.2688 52.3025C39.2011 52.5111 39.1781 52.7317 39.2011 52.9498H39.2228Z'
				fill='#7300E5'
			/>
			<path
				d='M163.213 52.9195C159.81 52.2043 156.523 54.1027 155.878 57.1582C155.591 58.5321 154.113 59.3859 152.583 59.0652C152.372 58.9902 152.148 58.961 151.926 58.9796C151.703 58.9982 151.487 59.0641 151.293 59.173C151.098 59.282 150.928 59.4313 150.796 59.611C150.664 59.7907 150.571 59.9967 150.525 60.2151C150.479 60.4334 150.48 60.6592 150.528 60.8771C150.577 61.095 150.671 61.3001 150.805 61.4785C150.939 61.657 151.11 61.8047 151.306 61.9117C151.502 62.0187 151.718 62.0826 151.941 62.0991C155.34 62.8142 158.631 60.9115 159.272 57.8603C159.563 56.4864 161.041 55.6326 162.571 55.9533C162.782 56.0284 163.006 56.0576 163.228 56.039C163.451 56.0204 163.667 55.9544 163.861 55.8455C164.056 55.7366 164.226 55.5872 164.358 55.4075C164.49 55.2278 164.583 55.0218 164.629 54.8035C164.675 54.5851 164.674 54.3594 164.626 54.1415C164.577 53.9235 164.483 53.7185 164.349 53.54C164.215 53.3616 164.044 53.2139 163.848 53.1068C163.652 52.9998 163.436 52.9359 163.213 52.9195Z'
				fill='#B266FF'
			/>
			<path
				d='M88.851 1.23106C86.5922 3.87485 86.6833 7.67152 89.0591 9.69987C90.1256 10.61 90.169 12.3003 89.1502 13.5052C88.9934 13.6575 88.8702 13.8409 88.7885 14.0436C88.7067 14.2463 88.6683 14.4638 88.6755 14.6823C88.6828 14.9007 88.7356 15.1152 88.8306 15.312C88.9256 15.5089 89.0607 15.6837 89.2272 15.8253C89.3937 15.9669 89.588 16.0721 89.7976 16.1343C90.0072 16.1965 90.2274 16.2142 90.4443 16.1863C90.6611 16.1585 90.8697 16.0856 91.0567 15.9725C91.2438 15.8593 91.4051 15.7083 91.5303 15.5292C93.7848 12.8898 93.6981 9.08877 91.3222 7.06475C90.2557 6.15025 90.2124 4.46429 91.2269 3.25508C91.3846 3.10387 91.509 2.9213 91.5919 2.71913C91.6748 2.51697 91.7144 2.29969 91.7082 2.08128C91.702 1.86288 91.6501 1.6482 91.5558 1.45106C91.4616 1.25393 91.3271 1.07871 91.161 0.93669C90.9949 0.794674 90.8009 0.689007 90.5915 0.626497C90.382 0.563987 90.1618 0.546018 89.9451 0.573749C89.7283 0.60148 89.5197 0.674294 89.3327 0.787504C89.1458 0.900714 88.9847 1.05181 88.8597 1.23106H88.851Z'
				fill='#B266FF'
			/>
			<path
				d='M44.9499 20.5135C45.8074 20.5135 46.6456 20.2593 47.3586 19.783C48.0715 19.3068 48.6272 18.6299 48.9554 17.838C49.2835 17.046 49.3694 16.1746 49.2021 15.3338C49.0348 14.4931 48.6219 13.7208 48.0155 13.1147C47.4092 12.5086 46.6367 12.0958 45.7957 11.9286C44.9547 11.7613 44.083 11.8472 43.2908 12.1752C42.4986 12.5032 41.8215 13.0587 41.3451 13.7715C40.8687 14.4842 40.6144 15.3222 40.6144 16.1794C40.6144 17.3288 41.0712 18.4312 41.8842 19.244C42.6973 20.0568 43.8001 20.5135 44.9499 20.5135ZM44.9499 14.5541C45.2714 14.5541 45.5858 14.6494 45.8532 14.828C46.1205 15.0066 46.3289 15.2604 46.4519 15.5574C46.575 15.8544 46.6072 16.1812 46.5445 16.4964C46.4817 16.8117 46.3269 17.1013 46.0995 17.3286C45.8721 17.5559 45.5825 17.7107 45.2671 17.7734C44.9517 17.8361 44.6248 17.804 44.3277 17.6809C44.0307 17.5579 43.7767 17.3496 43.5981 17.0823C43.4194 16.8151 43.3241 16.5008 43.3241 16.1794C43.3298 15.7521 43.5036 15.3442 43.8079 15.0441C44.1122 14.744 44.5224 14.5757 44.9499 14.5758V14.5541Z'
				fill='#ADC6FF'
			/>
			<path
				d='M178.301 71.9982C179.158 71.9982 179.996 71.744 180.709 71.2677C181.422 70.7915 181.978 70.1146 182.306 69.3227C182.634 68.5307 182.72 67.6593 182.553 66.8185C182.385 65.9778 181.973 65.2055 181.366 64.5994C180.76 63.9933 179.987 63.5805 179.146 63.4132C178.305 63.246 177.434 63.3318 176.641 63.6599C175.849 63.9879 175.172 64.5434 174.696 65.2562C174.219 65.9689 173.965 66.8069 173.965 67.6641C173.965 68.8135 174.422 69.9159 175.235 70.7287C176.048 71.5415 177.151 71.9982 178.301 71.9982ZM178.301 66.0388C178.622 66.0388 178.936 66.1341 179.204 66.3127C179.471 66.4913 179.68 66.7451 179.803 67.0421C179.926 67.3391 179.958 67.6659 179.895 67.9811C179.832 68.2964 179.678 68.586 179.45 68.8133C179.223 69.0406 178.933 69.1954 178.618 69.2581C178.302 69.3208 177.975 69.2886 177.678 69.1656C177.381 69.0426 177.127 68.8343 176.949 68.567C176.77 68.2998 176.675 67.9855 176.675 67.6641C176.676 67.2334 176.848 66.8206 177.152 66.5161C177.457 66.2115 177.87 66.0399 178.301 66.0388Z'
				fill='#ADC6FF'
			/>
		</svg>
	);
};

export const ChevronRightSvgNew = ({
	stroke = '#444',
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			fill='none'
			viewBox='0 0 12 12'
			{...props}
		>
			<path
				stroke={stroke}
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M3.5 11l5-5-5-5'
			></path>
		</svg>
	);
};

export const CloseSVGIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M13.3334 2.66663L2.66669 13.3333'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2.66669 2.66663L13.3334 13.3333'
			stroke='#444444'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const ChevronRightCitiesPage = ({ strokeColor = '#0F43BD' }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='16'
		height='16'
		fill='none'
		viewBox='0 0 16 16'
	>
		<path
			stroke={strokeColor}
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M4.667 14.667L11.333 8 4.667 1.333'
		></path>
	</svg>
);

export const DividerDotSvg = (props: any) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='3'
		height='3'
		fill='none'
		viewBox='0 0 3 3'
		{...props}
	>
		<path
			fill='#888'
			d='M.152 2.804H2.06c.036 0 .06-.024.06-.072V.788c0-.048-.024-.084-.06-.084H.152C.116.704.08.74.08.788v1.944c0 .048.024.072.072.072z'
		></path>
	</svg>
);
